export const imports = {
  'doc/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-index" */ 'doc/index.mdx'
    ),
  'doc/content/01_content_goals.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-content-01-content-goals" */ 'doc/content/01_content_goals.mdx'
    ),
  'doc/content/02_writing_principles.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-content-02-writing-principles" */ 'doc/content/02_writing_principles.mdx'
    ),
  'doc/content/03_brand_voice.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-content-03-brand-voice" */ 'doc/content/03_brand_voice.mdx'
    ),
  'doc/content/04_microcopy.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-content-04-microcopy" */ 'doc/content/04_microcopy.mdx'
    ),
  'doc/content/05_naming_rules.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-content-05-naming-rules" */ 'doc/content/05_naming_rules.mdx'
    ),
  'doc/patterns/form_nav.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-patterns-form-nav" */ 'doc/patterns/form_nav.mdx'
    ),
  'doc/visual/colors.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-visual-colors" */ 'doc/visual/colors.mdx'
    ),
  'doc/visual/elevation.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-visual-elevation" */ 'doc/visual/elevation.mdx'
    ),
  'doc/visual/spacing.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-visual-spacing" */ 'doc/visual/spacing.mdx'
    ),
  'doc/visual/typography.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-visual-typography" */ 'doc/visual/typography.mdx'
    ),
  'doc/mobile/components/action_destructive.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-mobile-components-action-destructive" */ 'doc/mobile/components/action_destructive.mdx'
    ),
  'doc/mobile/components/address.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-mobile-components-address" */ 'doc/mobile/components/address.mdx'
    ),
  'doc/mobile/components/basic.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-mobile-components-basic" */ 'doc/mobile/components/basic.mdx'
    ),
  'doc/mobile/components/basic_subtitled.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-mobile-components-basic-subtitled" */ 'doc/mobile/components/basic_subtitled.mdx'
    ),
  'doc/mobile/components/car_summary.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-mobile-components-car-summary" */ 'doc/mobile/components/car_summary.mdx'
    ),
  'doc/mobile/components/detail.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-mobile-components-detail" */ 'doc/mobile/components/detail.mdx'
    ),
  'doc/mobile/components/detail_collapsible.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-mobile-components-detail-collapsible" */ 'doc/mobile/components/detail_collapsible.mdx'
    ),
  'doc/mobile/components/expandable.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-mobile-components-expandable" */ 'doc/mobile/components/expandable.mdx'
    ),
  'doc/mobile/components/generic.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-mobile-components-generic" */ 'doc/mobile/components/generic.mdx'
    ),
  'doc/mobile/components/header.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-mobile-components-header" */ 'doc/mobile/components/header.mdx'
    ),
  'doc/mobile/components/highlights.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-mobile-components-highlights" */ 'doc/mobile/components/highlights.mdx'
    ),
  'doc/mobile/components/map.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-mobile-components-map" */ 'doc/mobile/components/map.mdx'
    ),
  'doc/mobile/components/message.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-mobile-components-message" */ 'doc/mobile/components/message.mdx'
    ),
  'doc/mobile/components/note.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-mobile-components-note" */ 'doc/mobile/components/note.mdx'
    ),
  'doc/mobile/components/slideshow.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-mobile-components-slideshow" */ 'doc/mobile/components/slideshow.mdx'
    ),
  'doc/mobile/components/specific.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-mobile-components-specific" */ 'doc/mobile/components/specific.mdx'
    ),
  'doc/mobile/components/user.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-mobile-components-user" */ 'doc/mobile/components/user.mdx'
    ),
  'doc/mobile/views/paginated.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-mobile-views-paginated" */ 'doc/mobile/views/paginated.mdx'
    ),
  'doc/mobile/views/sections.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "doc-mobile-views-sections" */ 'doc/mobile/views/sections.mdx'
    ),
  'src/components/BasicCell/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-basic-cell-index" */ 'src/components/BasicCell/index.mdx'
    ),
  'src/components/BulletList/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-bullet-list-index" */ 'src/components/BulletList/index.mdx'
    ),
  'src/components/Button/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-button-index" */ 'src/components/Button/index.mdx'
    ),
  'src/components/Callout/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-callout-index" */ 'src/components/Callout/index.mdx'
    ),
  'src/components/Card/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-card-index" */ 'src/components/Card/index.mdx'
    ),
  'src/components/Chip/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-chip-index" */ 'src/components/Chip/index.mdx'
    ),
  'src/components/EmptyState/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-empty-state-index" */ 'src/components/EmptyState/index.mdx'
    ),
  'src/components/Flash/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-flash-index" */ 'src/components/Flash/index.mdx'
    ),
  'src/components/Form/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-form-index" */ 'src/components/Form/index.mdx'
    ),
  'src/components/Icon/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-icon-index" */ 'src/components/Icon/index.mdx'
    ),
  'src/components/Note/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-note-index" */ 'src/components/Note/index.mdx'
    ),
  'src/components/Pill/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-pill-index" */ 'src/components/Pill/index.mdx'
    ),
  'src/components/ProgressBar/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-progress-bar-index" */ 'src/components/ProgressBar/index.mdx'
    ),
  'src/components/TabBar/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-tab-bar-index" */ 'src/components/TabBar/index.mdx'
    ),
  'src/components/Tag/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-tag-index" */ 'src/components/Tag/index.mdx'
    ),
  'src/components/TextContainer/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-text-container-index" */ 'src/components/TextContainer/index.mdx'
    ),
  'src/components/stories/documentation/Alerter.story.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-stories-documentation-alerter-story" */ 'src/components/stories/documentation/Alerter.story.mdx'
    ),
  'src/components/stories/documentation/Callout.story.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-stories-documentation-callout-story" */ 'src/components/stories/documentation/Callout.story.mdx'
    ),
  'src/components/stories/documentation/Modal.story.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-stories-documentation-modal-story" */ 'src/components/stories/documentation/Modal.story.mdx'
    ),
  'src/components/stories/documentation/Note.story.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-stories-documentation-note-story" */ 'src/components/stories/documentation/Note.story.mdx'
    ),
  'src/components/stories/documentation/Tabs.story.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-stories-documentation-tabs-story" */ 'src/components/stories/documentation/Tabs.story.mdx'
    ),
}
